import * as actionTypes from '../actions/actionTypes';

const initialstate = {
    showDashboard: true
}

const reducer = (state = initialstate, action) => {
     switch(action.type) {
         case actionTypes.HIDE_DASHBOARD:
             return {
				 ...state,
				 showDashboard: false
			 }
         default:
             return state;
     }
    return state;
}

export default reducer;
