import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './ContentTab.scss';

const ContentTab = () => {
	return (
		<React.Fragment>	
			<Tabs className="flex1 c-tab">
				<TabList className="c666">
					<Tab>ABOUT US</Tab>
					<Tab>SERVICE CONCEPT</Tab>
					<Tab>BUSINESS CONNECT</Tab>
					<Tab>CUSTOMER FOCUSED</Tab>
				</TabList>
		
				<TabPanel>
					<p className="fs14">ACD was established in Singapore in 2013 with a singular focus to provide reliable and professional services at competitive rates.
					From its humble beginnings, ACD increased its footprint to five destinations within Asia & Middle East handling over 95,000 guests annually We are a team of  seasoned professionals with a proven track record of delivering consistent value to its partners and shareholders.</p>
				</TabPanel>
				<TabPanel>
					<p className="fs14">Being a pure B2B DMC we understand the importance of aligning ourselves to your guests’ requirements – be it FIT, MICE or group traveler. Our meticulously maintained own fleet of vans and large coaches are at your disposal to ensure an unforgettable holiday. We carefully select and train our guides and chauffeur guides to ensure a great on ground experience We offer an exciting range of excursions and experiences that make for a perfect holiday at all destinations that we are present in.</p>
				</TabPanel>
				<TabPanel>
					<p className="fs14">Directly integrated with 400+ hotels across the UAE, Oman and jordan Dynamic search with 2-second response time User-friendly, OTA-compliant web services intergrated with partner booking systems Dedicated resources provide support for customised XML connectivity Minimal - zero investment required for set-up; powerful enouth to connect with major OTA platforms. </p>
				</TabPanel>
				<TabPanel>
					<p className="fs14">We speak your language! Our value-for-money approach with (y) our customer at heart has been on the forefront since our inception. We are driven in igniting the wanderlust in travelers in every aspect with the aim to exceed expectations.</p>
				</TabPanel>
		    </Tabs>
		</React.Fragment>
	)
}

export default ContentTab;
