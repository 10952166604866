import React, { Component } from 'react';
import Banner from '../../components/Banner/Banner';
import Destination from '../../components/Destination/Destination';
import Team from '../../components/Team/Team';
import Testimonial from '../../components/Testimonial/Testimonial';
import WhyWork from '../../components/WhyWork/WhyWork';
import ContentTab from '../../components/ContentTab/ContentTab';
import ContactUs from '../../components/ContactUs/ContactUs';
import NewsLetter from '../../components/NewsLetter/NewsLetter';

class Acd extends Component {
	render(){
		return (
			<React.Fragment>
				<Banner />
				<Testimonial />
				<Destination />
				<div className="content max-w dflex" id="aboutUs">
					<WhyWork />
					<ContentTab />
				</div>
				<div className="contact-us" id="contactUs">
					<ContactUs />
					<div className="">
						<NewsLetter />
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Acd;
