import React , {Component} from "react";
import './Banner.scss';
import BannerImg1 from '../../assets/images/slide-1.png';
import BannerImg2 from '../../assets/images/slide-2.png';
import BannerImg3 from '../../assets/images/slide-3.png';
import BannerImg4 from '../../assets/images/slide-4.png';

import BannerImg5 from '../../assets/images/thailand.png';
import BannerImg6 from '../../assets/images/uae2.png';
import BannerImg7 from '../../assets/images/malaysia.png';
import BannerImg8 from '../../assets/images/singapore.png';
import AliceCarousel from 'react-alice-carousel';
class Banner extends React.Component {
	responsive = {
		1024: { items: 1}
	  }
	render(){
		return(
			<React.Fragment>
				<AliceCarousel dotsDisabled={true} infinite={true}
					responsive={this.responsive} autoPlay={true} autoPlayInterval={3000}>
					<div>
						<img src={BannerImg1} />
					</div>
					<div>
						<img src={BannerImg2} />
					</div>
					<div>
					   <img src={BannerImg3} />
					</div>
					<div>
					   <img src={BannerImg4} />
					</div>
					<div>
					   <img src={BannerImg5} />
					</div>			
					<div>
					   <img src={BannerImg6} />
					</div>		
					<div>
					   <img src={BannerImg7} />
					</div>	
					<div>
					   <img src={BannerImg8} />
					</div>														
				</AliceCarousel>
			</React.Fragment>
		)
	}
}

export default Banner;
