import React from 'react';
import Logo from './Logo/Logo';
import TabList from './TabList/TabList';
import "./Header.scss";

const Header = (props) => {
    const menuItems  = [ 
        ["Home"],
        ["About Us", "#aboutUs"],
        ["Careers","#careers"],
        ["Contact Us","#contactUs"]
    ]

    return (
        <header>
			<div className="dflex">
				<Logo />
				<TabList items={menuItems} />
			</div>
        </header>
    );
}

export default Header;
