import React from 'react';
import { Link } from 'react-router-dom';
import './tab-list.scss';
import Button from '../../../UI/Button/Button';

const TabList = (props) => {
    return (
		<ul className="nav flex1 dflex item-h">
			{props.items.map((item) => 
				<li key={item[0]}> <a href={item[1]}> {item[0]} </a> </li>
			)}
			<li><Button btnType="btn-red b2b-login"><a href="https://asiandmc.online/">B2B Login</a></Button></li>
		</ul>
    ); 
}

export default TabList;
