import React from 'react';
import './ContactUs.scss';
import contactImage from '../../assets/images/contactUs.png';

const ContactUs = () => {
	return (
		<React.Fragment>
			<img src={contactImage} />
		</React.Fragment>
	)
}

export default ContactUs;
