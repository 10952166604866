import React from 'react';
import Cimage1 from '../../assets/images/indonesia-s.png'
import Cimage2 from '../../assets/images/malysia-s.png'
import Cimage3 from '../../assets/images/singapore-s.png'
import Cimage4 from '../../assets/images/dubai-s.png'
import Cimage5 from '../../assets/images/uae-s.png'
import './Destination.scss';
import Button from '../UI/Button/Button';
import AliceCarousel from 'react-alice-carousel';

class Destination extends React.Component {
	responsive = {
		0: { items: 1 },
		639: { items: 2 },
		1024: { items: 4 }
	  }
	render(){
		return (
			<React.Fragment>
					<div className="r-carousel wrapper mt80">
						<div className="header dflex justify-sb">
							<h3 className="fs26">Destinations</h3>
						</div>

						<AliceCarousel dotsDisabled={true}
							responsive={this.responsive}>
							<div>
								<img src={Cimage1} />
								<div className="legend">
									<p className="ctr">Indonesia</p>
								</div>
							</div>
							<div>
								<img src={Cimage2} />
								<div className="legend">
									<p className="ctr">Malaysia</p>
								</div>
							</div>
							<div>
							   <img src={Cimage3} />
								<div className="legend">
									<p className="ctr">Singapore</p>
								</div>
							</div>
							<div>
							   <img src={Cimage4} />
								<div className="legend">
									<p className="ctr">Thailand</p>
								</div>
							</div>
							<div>
							   <img src={Cimage5} />
								<div className="legend">
									<p className="ctr">Dubai</p>
								</div>
							</div>
						</AliceCarousel>
					</div>
			</React.Fragment>
		)
	}
}

export default Destination;
