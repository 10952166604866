import React from 'react';
import map from '../../../assets/images/map.png';
import twit from '../../../assets/images/twitter.png';
import './Footer.scss';

const Footer = (props) => {
	return (
		<React.Fragment>
			<ul className="wrapper footer">
				<li>
					<h3>Singapore Office</h3>
					<p className="lh30">Asia Consolidated DMC Pte. Ltd.<br/>
					906 SIMS AVENUE , #18-31 , PARC ESTA ,<br/>
					SINGAPORE (408969)<br/>
					Tel No. +65 97967384<br/>
					Email. acd-singapore@asiandmc.com
					</p>
				</li>
				<li>
					<img src={map} alt="map" />
				</li>
				<li>
					<h3>Follow Us</h3>
					<ul className="face-tab dflex mb20">
						<li><a href="https://twitter.com/asiandmc/"><img src={twit} alt="twit"/></a></li>
					</ul>
					<a href="mailto:info@asiandmc.com">info@asiandmc.com</a>
					<p className="mt10">@2019-24 Asian Dmc. All Right Reserved.</p>
				</li>
			</ul>
		</React.Fragment>
	)
}

export default Footer;
