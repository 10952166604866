import React,{Component} from 'react';
import Button from '../UI/Button/Button';
import './NewsLetter.scss';

class NewsLetter extends React.Component{
	state = {
		status : "false",
		email: "",
		errors:{
			email: ""
		}
	}
	dataChange(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	handleValidation(){
		let fields = Object.assign({},this.state);
		let formIsValid = true;

		if(fields["email"] === ""){
		   formIsValid = false;
		   fields.errors["email"] = "Cannot be empty";
		}
		else if(fields["email"] !== ""){
		   let lastAtPos = fields["email"].lastIndexOf('@');
		   let lastDotPos = fields["email"].lastIndexOf('.');

		   if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
			  formIsValid = false;
			  fields.errors["email"] = "Email is not valid";
				}
			}
			 else{
				 fields.errors["email"] = "";
			 }
			this.setState({errors: Object.assign({},fields.errors)});
	   		return formIsValid;
	}
	fetchData = () => {
		var self = this;
		if(this.handleValidation()){
			fetch("yatra-seo/acd-subscription?email="+this.state.email, {
				method: "GET",
				dataType: "JSON",
				headers: {
				  "Content-Type": "application/json; charset=utf-8",
				}
			  })
			  .then((resp) => {
					return resp.json()
			  })
			  .then((data) => {
					this.setState({status : data.status})
			  })
			  .catch((error) => {
					console.log(error,'catch the hoop');
			})
		}else{
			setTimeout(function(){
				self.setState(
					{errors:Object.assign({},{email: ""})})
				}, 3000);
        }
	}
	cross = () => {
		this.setState({status : "false"})
	}
	render(){
		return(
			<React.Fragment>
				<div className="mail">
					<h3>Contact Us</h3>
					<ul>
						<li>Singapore
							<a href="mailto:query.singapore@asiandmc.com">: query.singapore@asiandmc.com</a>
						</li>
						<li>Thailand
							<a href="mailto:query.thailand@asiandmc.com">: query.thailand@asiandmc.com</a>
						</li>
						<li>Malaysia
							 <a href="mailto:query.malaysia@asiandmc.com ">: query.malaysia@asiandmc.com</a>
						</li>
						<li>UAE
							<a href="mailto:query.uae@asiandmc.com">: query.uae@asiandmc.com</a>
						</li>
						<li>Bali
							<a href="mailto:query.bali@asiandmc.com">: query.bali@asiandmc.com</a>
						</li>
						<li>Group
							<a href="mailto:groups@asiandmc.com">: groups@asiandmc.com</a>
						</li>
					</ul>
				</div>
				<div className="newsletter">
					<h3>Newsletter</h3>
					{	this.state.status === 'false' ?
						<div className="input-grp">
							<label className="fs14">Subscribe for updates & promotions</label>
							<div className="input-wrapper">
								<div className="prel flex1">
								  <input type="email" name="email" placeholder="Please enter your email Id" value={this.state.email} onChange={this.dataChange.bind(this)} />
									<span className = {
										"error lft-eml " + (this.state.errors.email === "" ? 'hide':'')
									}>
										{this.state.errors.email}
									</span>
								</div>
								<Button btnType="btn-red" clicked={this.fetchData} disabled={this.state.disabled}>Subscribe</Button>
							</div>
						</div> : null
					}
					{
						this.state.status === 'true' ?
						<div className="thanks">Thank you for subscribing to our newsletter <span className="cross" onClick={this.cross}>X</span></div> : null
					}
				</div>
			</React.Fragment>
			)
	}
}

export default NewsLetter;
