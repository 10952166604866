import React,{Component} from 'react';
import TeamImg from '../../assets/images/team.png'
import './Team.scss';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

class Team extends React.Component {
	responsive = {
		0: { items: 1 },
		639: { items: 2 },
		1024: { items: 4 }
	  }
	render(){
		return (
			<React.Fragment>
				<div className="r-carousel wrapper mt80">
					<div className="header dflex justify-sb">
						<h3 className="fs26">Our Team</h3>
					</div>
					<AliceCarousel dotsDisabled={true} infinite={false}
						responsive={this.responsive}>
						<div>
							<img src={TeamImg} alt="legend"/>
							<p className="legend">Legend 1</p>
						</div>
						<div>
							<img src={TeamImg} alt="legend" />
							<p className="legend">Legend 2</p>
						</div>
						<div>
						   <img src={TeamImg} alt="legend"/>
							<p className="legend">Legend 3</p>
						</div>
						<div>
						   <img src={TeamImg} alt="legend"/>
							<p className="legend">Legend 3</p>
						</div>
						<div>
						   <img src={TeamImg} alt="legend"/>
							<p className="legend">Legend 3</p>
						</div>
					</AliceCarousel>
				</div>
			</React.Fragment>
		)
	}
}

export default Team;
