import React , {Component} from "react";
import './NoFound.scss';

class NoFound extends Component {
	render(){
		return (
			<div className="n-found">404 page</div>
		)
	}	
}

export default NoFound;
