import React from 'react';
import Button from '../UI/Button/Button';
import './Testimonial.scss';
import About from '../../assets/images/about.png';
import Carrers from '../../assets/images/carrers.png';
import Contact from '../../assets/images/contact.png';

const Testimonial = (props) => {
	return (
		<React.Fragment>
			<ul className="contactUs dflex wrapper" id="careers">
				<li className="txt-c">
					<a href="#aboutUs">
						<img src={About} alt="aboutUs"/>
						<h2>About Us</h2>
						<p>ACD is a Destination Management Company handling guests from India, South east Asia & Middle East into Singapore, Malaysia, Thailand, UAE & Indonesia.</p>
					</a>
				</li>
				<li className="txt-c">
					<a href="mailto:info@asiandmc.com">
						<img src={Carrers} alt="carrers" />
						<h2>Careers</h2>
						<p>Looking for the career opportunity with ACD info@asiandmc.com</p>
					</a>
				</li>
				<li className="txt-c">
					<a href="#contactUs">
						<img src={Contact} alt="contact" />
						<h2>Contact Us</h2>
						<p>Connect with us for questions, assistance or requests for collaboration.</p>
					</a>
				</li>
			</ul>
		</React.Fragment>
	)
}

export default Testimonial;
