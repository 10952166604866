import React from 'react';
import { Route , Switch, Redirect } from 'react-router-dom';
import Header from './components/common/Header/Header';
import Footer from './components/common/Footer/Footer';
import Acd from './pages/acd/acd';
import NoFound from './pages/NoFound/NoFound';

function App() {
  return (
      <React.Fragment>
	  		<Header/>
	  		<div className="container">
			  <Switch>
	  			<Route path="/" exact component={Acd} />
				<Redirect from="/" to="/" exact />
				<Route path="/*" exact component={NoFound} />
			  </Switch>
	  		</div>
	  		<Footer/>
      </React.Fragment>
  );
}

export default App;
