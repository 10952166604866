import React from 'react';
import './WhyWork.scss'

const WhyWrok = () => {
	return(
		<React.Fragment>
			<div className="why-work flex1 prel">
				<div className="c-cnt pabs">
					<h3 className="fs26">Why work with us?</h3>
					<p className="mt10">ACD is a true Asian Destination Management <br/> Company handling guests from India,<br/> South east Asia & Middle East into Singapore,<br/> Malaysia, Thailand, UAE & Indonesia.</p>
				</div>
			</div>
		</React.Fragment>
	)
		
}

export default WhyWrok;
